import { FunctionComponent, useState, useCallback } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { useDispatch } from 'react-redux';

// components
import { Link } from 'dibs-elements/exports/Link';
import Contact1stdibsModal from '../../contact1stdibs/Contact1stdibsModal';

import styles from '../Footer/LinkColumn/LinkColumnItem.scss';

// helpers
import { trackNavigationClick } from '../../helpers/trackingHelpers';
import { actionCreators } from '../../actions';

type Props = {
    trackingLabel: string;
};

const Contact1stdibsFooterLink: FunctionComponent<Props> = ({ trackingLabel }) => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const dispatch = useDispatch();

    const onChatStart = useCallback(
        () => dispatch(actionCreators.setActiveLiveChatVisibility(true)),
        [dispatch]
    );

    const onChatEnd = useCallback(
        () => dispatch(actionCreators.setActiveLiveChatVisibility(false)),
        [dispatch]
    );

    return (
        <li className={styles.item}>
            <Link
                onClick={event => {
                    setIsContactModalOpen(true);
                    trackNavigationClick({ label: trackingLabel }, event);
                }}
                className={styles.contact1stdibsLink}
                dataTn="footer-contact-1stdibs"
            >
                <FormattedMessage
                    id="dbl.Footer.site_links_contact_1stdibs"
                    defaultMessage="Get Help"
                />
            </Link>
            <Contact1stdibsModal
                isOpen={isContactModalOpen}
                placement="globalFooter"
                onClose={() => setIsContactModalOpen(false)}
                onChatStart={onChatStart}
                onChatEnd={onChatEnd}
            />
        </li>
    );
};

export default Contact1stdibsFooterLink;
